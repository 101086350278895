import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import "./index.css";
import zIndex from "@mui/material/styles/zIndex";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { mineData } from "../../data";

const Navbar = ({ navTitle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showToggle, setShowToggle] = useState(true);
  const [view, setView] = useState("list");

  const toggleView = (path) => {
    setView(!path.includes("mapview") ? "list" : "map");
  };

  const menuToggle = () => {
    const toggleMenu = document.querySelector(".menu");
    toggleMenu.classList.toggle("active");
  };

  useEffect(() => {
    console.log(location.pathname);
    toggleView(location.pathname);
    setShowToggle(!location.pathname.includes("maps"));
  }, [location.pathname]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-tertiary"
        data-bs-theme="dark"
        style={{ height: "60px" }}
      >
        <div className="container-fluid px-4 py-2">
          {/* <h4>F</h4> */}
          <h4>
            <Link
              to="/mapview"
              style={{ textDecoration: "none", color: "white" }}
            >
              <img
                width="120px"
                alt="logo"
                src="https://www.mahakumbhfestival.com/wp-content/uploads/2023/02/MahaKumbh_300x129.png"
              />
            </Link>
          </h4>
          {/* <a className="navbar-brand" href="#">PhiMine</a> */}
          <div
            className="text-white mt-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h5 className="p-0 m-0" style={{ color: "white" }}>
              {navTitle.name}
            </h5>
            <hr style={{ margin: "0" }} />
            {navTitle.name && (
              <p
                style={{
                  fontFamily: "font-family: Arial, sans-serif",
                  color: "rgb(166, 166, 166)",
                }}
              >
                <strong>Inspection Date:</strong>
                {navTitle.inspectionDate} | <strong>Inspection Area:</strong>
                {navTitle.inspectionArea}
              </p>
            )}
          </div>
          <div className="d-flex ">
            <div>
              <input
                id="search-input"
                className="form-control mr-sm-2 "
                type="search"
                style={{ marginTop: "2px" }}
                placeholder="Search"
                aria-label="Search"
              ></input>
            </div>
            <div className="action ">
              <div className="profile" onClick={menuToggle}>
                {/* <img src="/assets/icons/user.png" /> */}
                <PersonOutlineIcon fontSize="large" />
              </div>
              <div
                className="menu"
                style={{
                  zIndex: 99999,
                  boxShadow: "3px 7px 17px 0px rgba(0,0,0,0.75)",
                  paddingLeft: "10px",
                }}
              >
                <ul>
                  <li>
                    <img src="/icons/user.png" />
                    <a href="#">My profile</a>
                  </li>
                  <li>
                    <img src="/icons/settings.png" />
                    <a href="#">Setting</a>
                  </li>
                  <li onClick={() => navigate("/")}>
                    <img src="/icons/log-out.png" />
                    <a href="#">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
